import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdown from 'react-markdown'
const faqPage = (props) => (
  <Layout>
    <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-4 mt-8 max-w-6xl mx-auto ">
      <ReactMarkdown
        children={props?.data?.markdownRemark?.frontmatter?.content}
        components={{
          p: ({ children }) => {
            return (
              <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6">
                {children}
              </p>
            )
          },
          h1: ({ children }) => {
            return (
              <h1 className='text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8'>
                {children}
              </h1>
            )
          },
          h2: ({ children }) => {
            return (
              <h2 className='text-3xl text-gray-600 uppercase font-light title pb-5 mt-7 text-center mb-10 '>
                {children}
              </h2>
            )
          },
          h3: ({ children }) => {
            return (
              <h3 className='text-xl text-gray-600 uppercase font-light pb-2 mt-2 text-left mb-2'>
                {children}
              </h3>
            )
          },
          h5: ({ children }) => {
            return (
              <h5 className="question text-lg text-gray-700 font-semibold relative pl-8 mb-3 leading-6">
                {children}
              </h5>
            )
          },
          h6: ({ children }) => {
            return (
              <h6 className="text-base text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6">{children}</h6>
            )
          },

          ul: ({ children }) => {
            return (
              <ul className='md:mb-16 mb-6 pl-2 list-disc'>{children}</ul>
            )
          },
          li: ({ children }) => {
            return <li className='text-sm font-normal mb-3'>{children}</li>
          },
          a: ({ href, children }) => (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-blue-800"
            >
              {children}
            </a>
          )
        }}
      />
      {/* <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl leading-5 mr-2 font-bold absolute left-0 top-0">Q.</strong>What is vinyl fence?</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold absolute left-0 top-0">A.</strong>Vinyl fence is a type of plastic fencemade with PVC (polyvinyl chloride). The PVC resin is combined with special ingredients that give vinyl fenceexceptional impact strength, durability and weatherability. Vinyl is an easy-care alternative to other fence materials.</p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0 ">Q.</strong>How is vinyl fence installed?</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">A.</strong>We use 7′ long galvanized pipes that we drive 4′ deep in the ground. A vinyl fence post is sleeved on top of the pipe. </p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0 ">Q.</strong>What do we do when we hit a rock? </p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0 ">A.</strong>We go through the rock and continue driving</p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">Q.</strong>.Why is vinyl fence better then others?</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">A.</strong>A vinyl fence is a maintenance free fence with a manufacturer lifetime warranty.</p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">Q.</strong>Are there different colours?</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0 mb-6">A.</strong>We carry two popular colours<br />White or Tan</p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold font-bold leading-5 absolute left-0 top-0">Q.</strong>Why us?</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 pb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">A.</strong>Because that’s what we do!<br />We specialize on a vinyl fence installation for the past 10 years. </p>
            <p className="question text-sm text-gray-700 font-semibold relative pl-8 mb-3 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">Q.</strong>Why PVC fence</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full relative pl-8 leading-6"><strong className="text-2xl mr-2 font-bold leading-5 absolute left-0 top-0">A.</strong>PVC products currently represent the fastest growing segment in the residential fencing industry, which is why homeowners are continuously choosing Vinyl fence to increase the value and beauty of their properties.</p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full  relative pl-8 leading-6">Not only do our fences have significant visual appeal, the installation does not create damage to your backyard and leave the same as we got it in the morning.
                Our product will provide many years of maintenance-free privacy and security.<br />
                We use only Canadian product and our fence decals and railing are made in Toronto, Ontario and provide our customers a limited life time warranty by the manufacturer<br /></p>
            <p className="text-sm text-gray-700 font-light mb-4 max-w-full  relative pl-8 leading-6">We do our best efforts to provide best prices in order to make the fence affordable to all.</p>
        */}
    </div>
  </Layout>
)
export const Head = (props) => <Seo
  title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle}
  description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
  path={props.location.pathname}
/>


export const pageQuery = graphql`
  query FaqQuery{
    markdownRemark(frontmatter: {slug: {eq: "faq"}}) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
export default faqPage